import {SsabOrder} from '../../../../model/order.model';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ElementRef,
  EventEmitter,
  Input,
  Output, QueryList,
  ViewChildren
} from '@angular/core';
import {Sort, TablesTypes} from '../../../../model/misc.model';
import {SsabActiveCartService} from '../../../../service/cart/ssab-active-cart.service';
import {RoutingService} from "@spartacus/core";
import {SsabUnitOfMeasure} from "../../../../model/product.model";
import {SsabOrderEntry} from "../../../../model/cart.model";
import {Actions} from "@ngrx/effects";
import {tap} from "rxjs/operators";
import {SSAB_CART_ADD_ENTRY_SUCCESS} from "../../../../service/cart/ssab-entry.action";
import {subscribe} from "diagnostics_channel";


@Component({
  selector: 'ssab-cx-previous-orders-table',
  templateUrl: './ssab-previous-orders-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabPreviousOrdersTableComponent implements AfterViewInit {

  constructor(protected cdr: ChangeDetectorRef,
              protected routingService: RoutingService,
              protected actions$: Actions,
              protected activeCartService: SsabActiveCartService) {
  }

  @Input() orders: SsabOrder[];
  @Input() activeSort: Sort;
  @Output() sortChange = new EventEmitter<Sort>();
  tableTypes = TablesTypes;

  @ViewChildren('orderIcon') orderIcons: QueryList<ElementRef>;
  @ViewChildren('orderTable') orderTables: QueryList<ElementRef>;
  @ViewChildren('orderTr') orderTrs: QueryList<ElementRef>;

  ngAfterViewInit() {
    this.toggleFirstElement();
  }

  sort(sort: Sort): void {
    this.sortChange.emit(sort);
  }

  toggleEntries(event: MouseEvent, orderIcon: HTMLElement, orderTable: HTMLTableRowElement, orderTr: HTMLTableRowElement): void {
    const element = (event.target as HTMLElement);
    if (element.classList.contains('ssab-icon') && element.classList.contains('icon-cursor')) {
      orderTable.classList.toggle('d-none');
      orderIcon.classList.toggle('icon-rotate-180');
      orderTr.classList.toggle('active');
    }
  }

  repeatOrder(order: SsabOrder): void {
    this.activeCartService.addSsabEntries(order.entries
      .filter(e => e.product.purchasable)
      .map(e => (
          {
            quantity: e.quantity,
            product: {code: e.product.code},
            unit: {code: e.unit.code} as SsabUnitOfMeasure,
            warehouse: e.warehouse
          } as SsabOrderEntry
        )
      )
    );
    const sub = this.actions$.subscribe(action => {
      if (action.type === SSAB_CART_ADD_ENTRY_SUCCESS) {
        this.routingService.go('/cart');
        sub.unsubscribe();
      }
    })
  }

  reOrderEnabled(order: SsabOrder): boolean {
    return order.entries.filter(e => e.product.purchasable).length > 0;
  }

  isActive(orderTr: HTMLTableRowElement): boolean {
    return orderTr.classList.contains('active');
  }

  private toggleFirstElement(): void {
    // Ensure we have elements
    if (this.orderIcons.length && this.orderTables.length && this.orderTrs.length) {
      const firstOrderIcon = this.orderIcons.first.nativeElement;
      const firstOrderTable = this.orderTables.first.nativeElement;
      const firstOrderTr = this.orderTrs.first.nativeElement;

      // Toggle the classes
      firstOrderTable.classList.toggle('d-none');
      firstOrderIcon.classList.toggle('icon-rotate-180');
      firstOrderTr.classList.toggle('active');
    }
  }
}

