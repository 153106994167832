import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators} from "@angular/forms";
import {Address, UserAddressService, Country} from "@spartacus/core";
import {Observable, Subscription} from 'rxjs';
import {LAUNCH_CALLER, LaunchDialogService} from '@spartacus/storefront';
import {SsabCartNewAddressDialogData} from './ssab-cart-new-address-layout.config';
import {SsabGlobalconfigurationService} from "../../../../service/general/configuration/ssab-globalconfiguration.service";
import {map} from "rxjs/operators";


@Component({
  selector: 'ssab-cx-cart-new-address',
  templateUrl: './ssab-cart-new-address.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabCartNewAddressComponent implements OnInit, OnDestroy {

  addressForm: UntypedFormGroup;
  deliveryCountries$: Observable<Country[]>;
  subscriptions: Subscription = new Subscription();
  notAllowedPostalcodePrefixForNewAddress: string[] = [];

  constructor(
    protected fb: UntypedFormBuilder,
    protected userAddressService: UserAddressService,
    protected launchDialogService: LaunchDialogService,
    protected globalconfigurationService: SsabGlobalconfigurationService
  ) {
    this.subscriptions.add(
      this.globalconfigurationService.getGlobalConfiguration().subscribe(gc =>
        this.notAllowedPostalcodePrefixForNewAddress = gc.checkout.notAllowedPostalcodePrefixForNewAddress
      )
    );
  }

  static postalCodeValidator = (notAllowedPostalcodePrefixForNewAddress: string[]) => {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value.toUpperCase() as string;
      if (value.length == 0) {
        return null;
      }
      return notAllowedPostalcodePrefixForNewAddress.find(p => value.startsWith(p)) !== undefined ? {invalidPostalCode: true} : null;
    }
  }

  ngOnInit() {
    this.addressForm = this.fb.group(
      {
        company: ["", [Validators.required]],
        line1: ["", [Validators.required]],
        postalCode: ["", [Validators.required, SsabCartNewAddressComponent.postalCodeValidator(this.notAllowedPostalcodePrefixForNewAddress)]],
        city: ["", [Validators.required]],
        country: ["", [Validators.required]] // TODO
      });
    this.deliveryCountries$ = this.userAddressService.getDeliveryCountries();
    this.userAddressService.loadDeliveryCountries();
    this.subscriptions.add(
      this.userAddressService.getDeliveryCountries().subscribe(countries => {
        if (countries.length > 0 && !this.addressForm.get('country').value) {
          this.addressForm.get('country').setValue(countries[0].isocode);
        }
      })
    );
  }

  ngOnDestroy() {
    this.cancel();
    this.subscriptions.unsubscribe();
  }

  isValid() {
    return this.addressForm.valid;
  }

  isInvalid(fieldName: string) {
    if (fieldName === 'postalCode') {
      return this.addressForm.get(fieldName).errors?.postalCode === true;
    }
    return this.addressForm.get(fieldName) && this.addressForm.get(fieldName).invalid;
  }

  cancel() {
    this.launchDialogService.clear(LAUNCH_CALLER.CART_NEW_ADDRESS);
    this.launchDialogService.closeDialog('closed');
  }

  add() {
    if (this.addressForm.valid) {
      this.launchDialogService.closeDialog(null);
      this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.CART_NEW_ADDRESS, undefined, {
        newAddress: {
          companyName: this.addressForm.controls['company'].value,
          line1: this.addressForm.controls['line1'].value,
          postalCode: this.addressForm.controls['postalCode'].value,
          town: this.addressForm.controls['city'].value,
          country: {isocode: this.addressForm.controls['country'].value}
        } as Address
      } as SsabCartNewAddressDialogData);
      this.launchDialogService.clear(LAUNCH_CALLER.CART_NEW_ADDRESS);
      this.launchDialogService.closeDialog('closed');
    }
  }
}
