import {BrowserModule, BrowserTransferStateModule} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {AdministrationRootModule} from '@spartacus/organization/administration/root';
import {environment} from '../environments/environment';
import {GoogleTagManagerModule} from 'angular-google-tag-manager';
import {SpinnerModule} from '@spartacus/storefront';
import {registerLocaleData} from '@angular/common';
import localePl from '@angular/common/locales/pl';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeFi from '@angular/common/locales/fi';
import localeNl from '@angular/common/locales/nl';
import localePt from '@angular/common/locales/pt';
import localeRu from '@angular/common/locales/ru';
import localeSv from '@angular/common/locales/sv';
import localeTr from '@angular/common/locales/tr';
import localeZh from '@angular/common/locales/zh';
import localeDa from '@angular/common/locales/da';
import localeHu from '@angular/common/locales/hu';
import localeCs from '@angular/common/locales/cs';
import localeRo from '@angular/common/locales/ro';
import localeSl from '@angular/common/locales/sl';
import localeUk from '@angular/common/locales/uk';
import {SpartacusModule} from './spartacus/spartacus.module';
import {AppRoutingModule} from './app-routing.module';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {HttpClientModule} from '@angular/common/http';
import {SsabMainModule} from './spartacus/features/main/ssab-main.module';


// Here register all the locales that will be in use for Dates in the project
registerLocaleData(localePl, 'pl');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeFi, 'fi');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeNl, 'nl');
registerLocaleData(localePt, 'pt');
registerLocaleData(localeRu, 'ru');
registerLocaleData(localeSv, 'sv');
registerLocaleData(localeTr, 'tr');
registerLocaleData(localeZh, 'zh');
registerLocaleData(localeDa, 'da');
registerLocaleData(localeHu, 'hu');
registerLocaleData(localeRo, 'ro');
registerLocaleData(localeCs, 'cs');
registerLocaleData(localeSl, 'sl');
registerLocaleData(localeUk, 'uk');


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    SpartacusModule,
    BrowserModule.withServerTransition({appId: 'spartacus-app'}),
    BrowserTransferStateModule,
    BrowserAnimationsModule,
    AdministrationRootModule,
    GoogleTagManagerModule.forRoot({
      id: environment.googleManager.id,
    }),
    SpinnerModule,
    SsabMainModule,
    HttpClientModule,
    StoreModule.forRoot({}, {})
  ],
  bootstrap: [AppComponent]
})

export class AppModule {}
