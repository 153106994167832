import {NgModule} from '@angular/core';
import {i18nConfig} from '../../assets/i18n-assets/i18nConfig';
import {I18nConfig, OccConfig, provideConfig, provideConfigFactory, provideDefaultConfig} from '@spartacus/core';
import {Meta} from '@angular/platform-browser';

import {environment} from '../../environments/environment';
import {ssabHandlers} from './features/cms-components/shared/utils/handler';
import {ssabInterceptors} from './features/cms-components/shared/utils/interceptor';
import {ssabDefaultCmsModuleConfig} from './features/service/general/ssab-default-cms-config';
import {ssabB2bOccConfig} from './features/service/general/ssab-b2b-occ-config';
import {organizationTranslationChunksConfig, organizationTranslations} from '@spartacus/organization/administration/assets';
import {ssabLayoutConfig} from './features/cms/layout/config/ssab-layout-config';
import {defaultCmsContentProviders, LogoutGuard} from '@spartacus/storefront';
import {translationChunksConfig} from "@spartacus/assets";
import {bulkPricingTranslationChunksConfig} from "@spartacus/product/bulk-pricing/assets";
import {productImageZoomTranslationChunksConfig} from "@spartacus/product/image-zoom/assets";
import {productVariantsTranslationChunksConfig} from "@spartacus/product/variants/assets";
import {savedCartTranslationChunksConfig} from "@spartacus/cart/saved-cart/assets";
import {importExportTranslationChunksConfig} from "@spartacus/cart/import-export/assets";
import {quickOrderTranslationChunksConfig} from "@spartacus/cart/quick-order/assets";
import {asmTranslationChunksConfig} from "@spartacus/asm/assets";
import {orderTranslationChunksConfig} from "@spartacus/order/assets";
import {checkoutTranslationChunksConfig} from "@spartacus/checkout/base/assets";
import {orderApprovalTranslationChunksConfig} from "@spartacus/organization/order-approval/assets";
import {configuratorTranslationChunksConfig} from "@spartacus/product-configurator/common/assets";
import {storeFinderTranslationChunksConfig} from "@spartacus/storefinder/assets";
import {SsabLogoutGuard} from "./features/service/logout/ssab-logout.guard";

const occConfig: OccConfig = {
  backend: {
    occ: {
      prefix: '/rest/v2/'
    }
  }
};

const initializeBackendUrl = (meta: Meta) => {
  const backendUrlFromEnvConfig = environment.backend.occ.baseUrl; // local
  const backendUrlFromMetaTag = meta.getTag('name=occ-backend-base-url').content; // ccv2
  const backendUrlToUse = backendUrlFromEnvConfig ? backendUrlFromEnvConfig : backendUrlFromMetaTag; // env config has priority
  const baseUrl = backendUrlToUse.replace('https://int-', 'https://');
  i18Backend.loadPath = baseUrl + '/rest/v2/global/translations/{{ns}}.json?lang={{lng}}';
  return {
    backend: {
      occ: {
        baseUrl: backendUrlToUse.replace('https://int-', 'https://')
      }
    }
  };
};

const i18Backend = {
  loadPath: '',
};


@NgModule({
  imports: [],
  providers: [
    {provide: LogoutGuard, useExisting: SsabLogoutGuard},
    provideConfig({
      personalization: {
        enabled: true,
      },
      backend: occConfig.backend,
      context: {
        urlParameters: ['baseSite', 'language', 'currency'],
        baseSite: ['global', 'poland', 'benelux', 'gcp', 'france', 'spain', 'hungary', 'denmark', 'romania', 'slovenia', 'germany', 'austria', 'switzerland', 'gb'],
        currency: ['EUR', 'PLN', 'DKK', 'CZK', 'HUF', 'RON', 'SEK', 'CHF', 'GBP'],
        language: ['en', 'pl', 'fr', 'es', 'de', 'hu', 'pt', 'ru', 'tr', 'zh', 'nl', 'fi', 'sv', 'da', 'cs', 'ro', 'sl']
      },
      features: {
        level: '3.0'
      },
      ...i18nConfig,
      i18n: {
        backend: i18Backend
      },
      breakpoints: {
        xs: 576,
        sm: 768,
        md: 992,
        lg: 1200,
        xl: 1440,
      }
    }),
    provideConfigFactory(initializeBackendUrl, [Meta]),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: organizationTranslations,
        chunks: organizationTranslationChunksConfig,
      },
    }),
    provideDefaultConfig(ssabLayoutConfig),
    provideDefaultConfig(ssabB2bOccConfig),
    provideDefaultConfig(ssabDefaultCmsModuleConfig),
    ssabInterceptors,
    ssabHandlers,
    ...defaultCmsContentProviders,
  ],
})
export class SpartacusConfigurationModule {
}
