<ssab-cx-carousel
  [showBanners]="false"
  [items]="items$ | async"
  [title]="title$ | async"
  [template]="carouselItem"
  itemWidth="{{sizeOfPicture()}}"
  [hideIndicators]="true"
>
</ssab-cx-carousel>

<ng-container *ngIf="data$ | async as data">
  <ng-container *ngIf="items$ | async as items">
    <div class="cx-carousel-content" *ngIf="items.length > 0">
      <cx-generic-link
        class="link-underline text-primary text-md-right text-center"
        *ngIf="data.link"
        [url]="data.link.url"
        [style]="data.link.styleAttributes">
        {{data.link.linkName}}
      </cx-generic-link>
    </div>
  </ng-container>
</ng-container>

<ng-template #carouselItem let-item="item">
  <a tabindex="0" class="text-default" [routerLink]="{ cxRoute: 'product', params: item } | cxUrl">
    <p class="mb-3">{{ item.name }}</p>
    <cx-media class="mb-4 w-100" [container]="item.images?.PRIMARY" format="product"></cx-media>
  </a>
  <ssab-cx-add-to-cart
    [showQuantity]="true"
    [selectedUnit]="item.unit"
    [product]="item"
    [quantity]="item.stock.quantity"
    [currentStock]="item.stock"
    [minQuantity]="item.stock.minimumAvailableQuantity"
    [stockLevel]="item.stock.totalAmount"
    [isPLPView]="false"
    [isReorder]="false"
    [isFavoriteCarousel]="true"
    [isSplitBundle]="item.splitBundle"
  ></ssab-cx-add-to-cart>
</ng-template>
