<div class="row align-items-center col-no-margin pt-0">
  <!-- Item Information -->
  <div
    class="align-self-start"
    [ngClass]="showPricesOrConsignmentPricesStock? 'cx-item-list-desc col-4 row' : 'cx-item-list-desc col-5 row'"
    *ngFor="let product of getProduct()">
    <!--Item image -->
    <div class="col-2 cx-image-container col-no-padding">
      <a
        [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
        cxModal="dismiss"
        tabindex="-1"
      >
        <cx-media
          [container]="product.images?.PRIMARY"
          format="cartIcon"
        ></cx-media>
      </a>
    </div>
    <!--Product name -->
    <div class="col-9">
      <div class="text-opacity">{{product.code}}</div>
      <div class="text-opacity" *ngIf="product.customerArticleIds">
        {{product.customerArticleIds.join(',')}}
      </div>
      <a
        class="cx-link text-bold text-default"
        [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
        cxModal="dismiss"
      >{{product.name }}</a
      >
    </div>

  </div>

  <div class="col-8 ml-auto pr-0">
    <ng-container *ngFor="let item of items">
      <div class="cx-item-consignment has-left-limiter row col-12 no-gutters py-3"
           [ngClass]="{'item-error': itemHasErrors(item), 'has-border': !item.canSplitBundle}">
        <div class="col-3" [ngClass]="{'text-opacity': itemHasErrors(item)}">
          {{!item.vendorBatchId || item.batchId === item.vendorBatchId ? item.batchId : item.batchId + ' / ' + item.vendorBatchId}}
        </div>

        <div class="cx-item-list-qty col-2" [ngClass]="{'text-opacity': itemHasErrors(item)}">

          <span *ngIf="!isSplitBatchOnList(item.batchId) || readonly || orderConfirmation">{{item.doubleQuantity}}</span>
          <ssab-cx-item-stepper
            *ngIf="isSplitBatchOnList(item.batchId) && !readonly && !orderConfirmation"
            [isValueChangeable]="true"
            [showButtons]="true"
            [max]="getMaxQuantity(item)"
            [min]="item.product.stock.bundleSize"
            [value]="item.quantity"
            [step]="item.product.stock.bundleSize"
            [stepperTimeout]="0"
            [id]="item.product.code+'_stepper_consignment_item'"
            (update)="quantityChange($event, item, item.splitBundle)"
            class="stepper-price"
          ></ssab-cx-item-stepper>
        </div>
        <div class="col-1" [ngClass]="{'text-opacity': itemHasErrors(item)}">
          <span [innerHTML]="item.unit?.name"></span>
        </div>
        <div class="col-2" [ngClass]="{'text-opacity': itemHasErrors(item)}">
          <div class="mr-2">
            {{ item.warehouse.name }}
          </div>
        </div>
        <ng-container *ngIf="showPricesOrConsignmentPricesStock">

          <div class="cx-item-list-price col-2" [ngClass]="{'text-opacity': itemHasErrors(item)}">
            <ssab-cx-product-comparison-price
              [product]="item.product"
              [currentPrice]="item.basePrice"
              [selectedUnit]="item.unit"
              [orderPage]="true">
            </ssab-cx-product-comparison-price>
          </div>
          <div class="cx-item-list-price col-1" [ngClass]="{'text-opacity': itemHasErrors(item)}">
            {{ item.totalPrice?.formattedValue }}
          </div>
        </ng-container>
        <div class="col-1">
          <ssab-cart-item-consignment-label
            [currentItem]="item"
            [readonly]="readonly"
          ></ssab-cart-item-consignment-label>
          <button type="button"
                  class="ssab-icon icon-system-trash icon-cursor"
                  [ngClass]="{'icon-red': itemHasErrors(item)}"
                  *ngIf="!readonly"
                  (click)="deleteEntry(item)">
          </button>
        </div>
      </div>
      <div class="col-12 cx-item-consignment has-border pb-3" *ngIf="item.canSplitBundle">
        <ssab-cx-bundle-split-toggle *ngIf="!readonly"
                                     [label]="'ssab.user.bundleSplit.consignment.toggle'"
                                     [isSplitBundle]="item.splitBundle"
                                     (updateSplitBundle)="updateEntry(item, $event)"
        ></ssab-cx-bundle-split-toggle>
      </div>
    </ng-container>
  </div>
</div>
